import React, { useState, useEffect, useRef } from "react";
import { View, FlatList, Text, StyleSheet, TouchableOpacity, Animated, Modal, Dimensions, ActivityIndicator } from "react-native";
import RatingStars from "./RatingStars";
import BusDetailsModal from "../modals/BusDetailsModal";
import { AmenityIcon } from "../common/FeatureComponent";
import SeatArrangement from "./SeatArrangement";
import { MaterialCommunityIcons, SimpleLineIcons, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import moment from 'moment';
import axios from "axios";
import { isMobile, isBrowser } from 'react-device-detect';
import { format } from 'date-fns';
import Constants from 'expo-constants';
import { useUser } from './UserContext';

const BUS_AVAILABILITY_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusAvailabilityResults = ({ busAvailabilities, bookSummary, handleBookSummaryChange, onBook, onLoginRequest }) => {
    const [seatArrangement, setSeatArrangement] = useState(null);
    const [selectedBus, setSelectedBus] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [showBusDetails, setShowBusDetails] = useState(false);
    const [activeTab, setActiveTab] = useState('Features');
    const [showSeatArrangement, setShowSeatArrangement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTotalCostForItem, setShowTotalCostForItem] = useState({});
    const [serviceInfo, setSericeInfo] = useState({});
    const { user, userPoints } = useUser();

    const colorValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        const animateColor = () => {
            Animated.loop(
                Animated.sequence([
                    Animated.timing(colorValue, {
                        toValue: 1,
                        duration: 1000,
                        useNativeDriver: false,
                    }),
                    Animated.timing(colorValue, {
                        toValue: 0,
                        duration: 1000,
                        useNativeDriver: false,
                    }),
                ])
            ).start();
        };
        animateColor();
    }, [colorValue]);


    const calculateRewardPointsRedemption = (basePrice) => {
        if (!bookSummary.useRewardPoints || !user?.userId) return null;
        
        // Convert price to paise (1 rupee = 100 paise)
        const basePricePaise = basePrice * 100;
        // Use all available points up to the total price in paise
        const pointsToUse = Math.min(basePricePaise, userPoints);
        // Convert points value to rupees (1 point = 1 paise = 0.01 rupees)
        const pointsDiscount = pointsToUse / 100;
        
        return {
            pointsToUse,
            pointsDiscount,
            remainingPrice: Math.max(0, basePrice - pointsDiscount)
        };
    };

    const calculateRewardPoints = (basePrice, discountedPrice, pointsUsed = 0) => {
        if (!user?.userId) return null;
        
        // Calculate price in paise, only on the amount actually paid
        const priceForReward = pointsUsed 
            ? ((discountedPrice || basePrice) * 100) - pointsUsed  // Subtract points used (1 point = 1 paise)
            : ((discountedPrice || basePrice) * 100);
            
        return Math.max(0, Math.round(priceForReward * 0.01)); // 1% of price in paise
    };

    const getTotalPriceWithTaxes = (basePrice, totalPrice, priceDetails) => {
        if (!basePrice || !totalPrice) return null;
        
        const taxAmount = Number((totalPrice - basePrice).toFixed(2));
        
        if (priceDetails) {
            return Number((priceDetails.finalPrice + taxAmount).toFixed(2));
        }
        
        return Number(totalPrice.toFixed(2));
    };

    const calculateDiscount = (price) => {
        if (!bookSummary.validatedPromo?.valid || !price) return null;
        
        const promoDetails = bookSummary.validatedPromo;
        
        if (promoDetails.discountDetails.minimumBookingAmount && 
            price < promoDetails.discountDetails.minimumBookingAmount) {
            return null;
        }

        let discountAmount = 0;
        switch (promoDetails.discountDetails.discountType) {
            case 'PERCENTAGE':
                discountAmount = (price * promoDetails.discountDetails.discountValue) / 100;
                break;
            case 'FIXED':
                discountAmount = promoDetails.discountDetails.discountValue;
                break;
            default:
                return null;
        }

        if (promoDetails.discountDetails.maximumDiscount) {
            discountAmount = Math.min(discountAmount, promoDetails.discountDetails.maximumDiscount);
        }

        return {
            originalPrice: price,
            discountAmount: discountAmount,
            finalPrice: Math.max(0, price - discountAmount)
        };
    };

    const getLowestPrice = (seatTicketFares) => {
        if (!seatTicketFares || seatTicketFares.length === 0) return null;
        return seatTicketFares.reduce((lowest, current) => {
            const currentPrice = current.ticketFare.discountedBaseFare || current.ticketFare.baseFare;
            return !lowest || currentPrice < lowest ? currentPrice : lowest;
        }, null);
    };

    const getLowestTotalPrice = (seatTicketFares) => {
        if (!seatTicketFares || seatTicketFares.length === 0) return null;
        return seatTicketFares.reduce((lowest, current) => {
            const currentPrice = current.ticketFare.totalCost;
            return !lowest || currentPrice < lowest ? currentPrice : lowest;
        }, null);
    };

    const getTotalAvailableSeats = (seatTicketFares) => {
        if (!seatTicketFares) return 0;
        return seatTicketFares.reduce((total, fare) => total + fare.availableSeatCount, 0);
    };

    const handleCloseBusDetailModal = () => {
        setActiveTab(null);
        setShowBusDetails(false);
    };

    const handleDetailClickButton = (item, detail) => {
        setActiveTab(detail);
        setSelectedBus(item);
        setShowBusDetails(true);
    };

    const handleShowSeatArrangement = (busAvailability) => {
        setLoading(true);
        setShowSeatArrangement(true);
        setSelectedBus(busAvailability);
        setSelectedSeats([]);
        const updatedBookSummary = { ...bookSummary, busAvailability: busAvailability, seats: [] };
        handleBookSummaryChange(updatedBookSummary);
        handleSeatAvailability(busAvailability);
        setLoading(false);
    };

    const handleCloseSeatAlignment = () => {
        setSelectedBus(null);
        setSelectedSeats([]);
        setShowSeatArrangement(false);
        const updatedBookSummary = { ...bookSummary, busAvailability: null, seats: [] };
        handleBookSummaryChange(updatedBookSummary);
    };

    const handleToggleTotalCost = (item) => {
        setShowTotalCostForItem(prevState => ({
            ...prevState,
            [item.tripIdentifier]: !prevState[item.tripIdentifier]
        }));
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleSeatClick = (selectedSeat) => {
        setSelectedSeats((prevSelectedSeats) => {
            const isSeatSelected = prevSelectedSeats.some((seat) => seat.seatNo === selectedSeat.seatNo);
            let updatedSelectedSeats;
            if (isSeatSelected) {
                updatedSelectedSeats = prevSelectedSeats.filter((seat) => seat.seatNo !== selectedSeat.seatNo);
            } else {
                updatedSelectedSeats = [...prevSelectedSeats, selectedSeat];
            }

            const totalCost = calculateEstimatedTotalCost(updatedSelectedSeats);
            const updatedBookSummary = { 
                ...bookSummary, 
                seats: updatedSelectedSeats, 
                totalCost: totalCost
            };
            handleBookSummaryChange(updatedBookSummary);
            return updatedSelectedSeats;
        });
    };

    const calculateEstimatedTotalCost = (seats) => {
        let totalCost = 0;
        seats.forEach((seat) => {
            totalCost += seat.seatRate.totalCost;
        });
        return totalCost;
    };

    const handleSeatAvailability = async (busAvailability) => {
        setSeatArrangement(null);
        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const response = await axios.get(`${BUS_AVAILABILITY_URL}/seatavailability`, {
                params: {
                    tripIdentifier: busAvailability.tripIdentifier,
                    origin: bookSummary.origin,
                    destination: bookSummary.destination,
                    travelDate: formattedDate,
                    userId: user?.userId,
                    operator: busAvailability.vendorCode,
                    promoCode: bookSummary.validatedPromo ? bookSummary.validatedPromo.offerCode : null
                },
                headers: { 'x-api-token': API_TOKEN }
            });
            setSeatArrangement(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getServiceInfo = async (serviceIdentifier) => {
        try {
            const response = await axios.get(`${BUS_AVAILABILITY_URL}/businfo/${serviceIdentifier}`, {
                headers: { 'x-api-token': API_TOKEN }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const fetchAllServiceInfo = async () => {
        const serviceInfoMap = {};
        for (const item of busAvailabilities) {
            if (item.serviceIdentifier) {
                const serviceInfo = await getServiceInfo(item.serviceIdentifier);
                serviceInfoMap[item.serviceIdentifier] = serviceInfo;
            }
        }
        setSericeInfo(serviceInfoMap);
    };

    useEffect(() => {
        fetchAllServiceInfo();
    }, [busAvailabilities]);

    const renderRewardsSection = (basePrice, discountedPrice) => {
        const points = calculateRewardPoints(basePrice, discountedPrice);
        
        if (!user) {
            return (
                <TouchableOpacity 
                    style={styles.rewardsContainer} 
                    onPress={onLoginRequest}
                >
                    <MaterialIcons name="loyalty" size={16} color="#0066cc" />
                    <Text style={styles.rewardsText}>
                        Login to earn {points} reward points
                    </Text>
                </TouchableOpacity>
            );
        }

        return (
            <View style={styles.rewardsContainer}>
                <MaterialIcons name="loyalty" size={16} color="#34c759" />
                <Text style={styles.rewardsEarnText}>
                    Earn {points} reward points
                </Text>
            </View>
        );
    };

    const renderPriceSection = (basePrice, totalPrice, item) => {
        const priceDetails = calculateDiscount(basePrice);
        const pointsRedemption = calculateRewardPointsRedemption(priceDetails ? priceDetails.finalPrice : basePrice);
        const finalBasePrice = pointsRedemption ? pointsRedemption.remainingPrice : (priceDetails ? priceDetails.finalPrice : basePrice);
        const finalTotalPrice = getTotalPriceWithTaxes(basePrice, totalPrice, priceDetails);
        
        const earnablePoints = calculateRewardPoints(
            basePrice,
            priceDetails?.finalPrice,
            pointsRedemption?.pointsToUse
        );

        return (
            <View style={styles.priceContainer}>
                <Text style={styles.availableCount}>
                    Available {getTotalAvailableSeats(item.seatTicketFares)}
                </Text>
                
                {bookSummary.useRewardPoints && pointsRedemption ? (
                    <View>
                        <View style={styles.priceRow}>
                            <Text style={styles.originalPrice}>₹{basePrice}</Text>
                            <View style={styles.pointsRedemptionBadge}>
                                <Text style={styles.pointsRedemptionText}>
                                    {pointsRedemption.pointsToUse} Points Used
                                </Text>
                            </View>
                        </View>
                        <Text style={styles.pointsValueText}>
                            Points Value: ₹{pointsRedemption.pointsDiscount.toFixed(2)}
                        </Text>
                        <Text style={styles.discountedPrice}>
                            Pay: ₹{finalBasePrice.toFixed(2)}
                        </Text>
                        {showTotalCostForItem[item.tripIdentifier] && (
                            <Text style={styles.taxInclusiveExclusive}>
                                ₹{(finalBasePrice + (finalTotalPrice - basePrice)).toFixed(2)} Incl Taxes
                            </Text>
                        )}
                        {earnablePoints > 0 && (
                            <View style={styles.rewardsContainer}>
                                <MaterialIcons name="loyalty" size={16} color="#34c759" />
                                <Text style={styles.rewardsEarnText}>
                                    Earn {earnablePoints} points on final payment
                                </Text>
                            </View>
                        )}
                    </View>
                ) : (
                    <View>
                        {priceDetails ? (
                            <View>
                                <View style={styles.priceRow}>
                                    <Text style={styles.originalPrice}>₹{basePrice}</Text>
                                    <View style={styles.discountBadge}>
                                        <Text style={styles.discountText}>
                                            Promo Applied
                                        </Text>
                                    </View>
                                </View>
                                <Text style={styles.discountedPrice}>₹{priceDetails.finalPrice}</Text>
                                {priceDetails.finalPrice < bookSummary.validatedPromo.discountDetails.minimumBookingAmount && (
                                    <Text style={styles.minBookingWarning}>
                                        *Min booking amount ₹{bookSummary.validatedPromo.discountDetails.minimumBookingAmount}
                                    </Text>
                                )}
                            </View>
                        ) : (
                            <Text style={styles.ticketFare}>Starting from ₹{basePrice}</Text>
                        )}
                        
                        {showTotalCostForItem[item.tripIdentifier] && (
                            <Text style={styles.taxInclusiveExclusive}>
                                ₹{finalTotalPrice} Incl Taxes
                            </Text>
                        )}
                        
                        {renderRewardsSection(basePrice, priceDetails?.finalPrice)}
                    </View>
                )}

                {!showTotalCostForItem[item.tripIdentifier] && (
                    <TouchableOpacity onPress={() => handleToggleTotalCost(item)}>
                        <Text style={styles.showTaxInclusiveText}>
                            Show Cost Including Taxes
                        </Text>
                    </TouchableOpacity>
                )}
            </View>
        );
    };

    const renderItem = ({ item }) => {
        const basePrice = getLowestPrice(item.seatTicketFares);
        const totalPrice = getLowestTotalPrice(item.seatTicketFares);

        return (
            <View>
                <View style={styles.busAvailabilityContainer}>
                    {serviceInfo[item.serviceIdentifier] && serviceInfo[item.serviceIdentifier].isPopular && (
                        <View style={styles.popularBand}>
                            <Text style={styles.popularText}>Popular</Text>
                        </View>
                    )}
                    <View style={styles.leftContainer}>
                        <Text style={styles.operator}>{item.operator}</Text>
                        <Text style={styles.bus}>{item.bus.name}</Text>
                        {item.serviceIdentifier && (
                            <RatingStars rating={serviceInfo[item.serviceIdentifier]?.overallRating || 0} />
                        )}
                        <View style={styles.amenitiesContainer}>
                            {item.amenities?.slice(0, 4).map((amenity, index) => (
                                <AmenityIcon key={index} amenity={amenity} size={12} color="black" />
                            ))}
                            {item.amenities?.length > 4 && (
                                <TouchableOpacity onPress={() => handleDetailClickButton(item, 'Features')}>
                                    <Text style={styles.showAllFeatures}>Show All</Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                    <View style={styles.middleContainer}>
                        <View style={styles.originDestinationContainer}>
                            <View style={styles.originContainer}>
                                <Text style={styles.locationText}>{item.origin}</Text>
                                <Text style={styles.timeText}>{formatTime(item.departureTime)}</Text>
                            </View>
                            <View style={styles.durationContainer}>
                                <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                                <Text style={styles.dottedLine}>-----</Text>
                                <Text style={styles.durationText}>{item.travelTime}</Text>
                                <Text style={styles.dottedLine}>-----</Text>
                                <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                            </View>
                            <View style={styles.destinationContainer}>
                                <Text style={styles.locationText}>{item.destination}</Text>
                                <Text style={styles.timeText}>{formatTime(item.arrivalTime)}</Text>
                            </View>
                        </View>
                        <View style={styles.detailsContainer}>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Cancel Policy')}>
                                <Text style={styles.text}>Cancel Policy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Travel Policy')}>
                                <Text style={styles.text}>Travel Policy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Boarding DropOff Points')}>
                                <Text style={styles.text}>Boarding/DropOff Points</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.rightContainer}>
                        {renderPriceSection(basePrice, totalPrice, item)}
                        <View style={styles.showSeatsContainer}>
                            <TouchableOpacity 
                                onPress={() => showSeatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier 
                                    ? handleCloseSeatAlignment()
                                    : handleShowSeatArrangement(item)
                                }
                            >
                                <Text style={styles.showSeatAvailability}>
                                    {showSeatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier 
                                        ? 'Hide Seats' : 'Check Seats'
                                    }
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                {loading && (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size="large" color="#5EBC67" />
                        <FontAwesome5 name="bus" size={24} color="#5EBC67" style={styles.busIcon} />
                    </View>
                )}
                {showSeatArrangement && seatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier && (
                    <View style={styles.seatArrangementContainer}>
                        <SeatArrangement
                            levels={seatArrangement.levels}
                            onSeatClick={handleSeatClick}
                            selectedSeats={selectedSeats}
                            onCancel={handleCloseSeatAlignment}
                            onBook={onBook}
                            useRewardPoints={bookSummary.useRewardPoints}
                        />
                    </View>
                )}
                {showBusDetails && selectedBus === item && (
                    <BusDetailsModal 
                        activatedTab={activeTab} 
                        closeModal={handleCloseBusDetailModal} 
                        busDetails={selectedBus} 
                    />
                )}
            </View>
        );
    };

    return (
        <View style={styles.resultsContainer}>
            <FlatList
                style={styles.busAvailabilities}
                data={busAvailabilities}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={renderItem}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    resultsContainer: {
        marginHorizontal: '5%',
        marginTop: 10,
        width: '90%',
        flexGrow: 1,
    },
    busAvailabilities: {
        width: '100%'
    },
    busAvailabilityContainer: {
        width: "100%",
        borderRadius: 12,
        marginVertical: 8,
        flexDirection: 'row',
        flex: 1,
        flexWrap: "wrap",
        justifyContent: "center",
        backgroundColor: 'white',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    leftContainer: {
        alignItems: "center",
        justifyContent: 'center',
        padding: 12,
        margin: 5,
        minWidth: '30%',
        borderRightWidth: 1,
        borderRightColor: '#f0f0f0',
    },
    middleContainer: {
        alignItems: "center",
        padding: 12,
        margin: 5,
        minWidth: '30%',
        borderRightWidth: 1,
        borderRightColor: '#f0f0f0',
    },
    rightContainer: {
        alignItems: "center",
        justifyContent: "space-between",
        padding: 12,
        minWidth: '30%',
        flex: 'auto',
        backgroundColor: '#f8f9fa',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
    },
    priceContainer: {
        alignItems: "center",
        padding: 12,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 8,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
    },
    priceRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 4,
    },
    originalPrice: {
        fontSize: 16,
        color: '#666',
        textDecorationLine: 'line-through',
        marginRight: 8,
    },
    discountedPrice: {
        fontSize: 22,
        color: '#34c759',
        fontWeight: '700',
        letterSpacing: 0.5,
    },
    discountBadge: {
        backgroundColor: '#34c75915',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
    },
    discountText: {
        color: '#34c759',
        fontSize: 12,
        fontWeight: '600',
    },
    pointsRedemptionBadge: {
        backgroundColor: '#5856d615',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        marginLeft: 8,
    },
    pointsRedemptionText: {
        color: '#5856d6',
        fontSize: 12,
        fontWeight: '600',
    },
    pointsValueText: {
        fontSize: 12,
        color: '#666',
        marginTop: 2,
    },
    minBookingWarning: {
        fontSize: 11,
        color: '#ff3b30',
        marginTop: 2,
    },
    rewardsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
        padding: 4,
    },
    rewardsText: {
        fontSize: 12,
        color: '#0066cc',
        marginLeft: 4,
        fontWeight: '500',
    },
    rewardsEarnText: {
        fontSize: 12,
        color: '#34c759',
        marginLeft: 4,
        fontWeight: '500',
    },
    operator: {
        fontSize: 16,
        fontWeight: '600',
        color: '#1a1a1a',
        marginBottom: 4,
    },
    bus: {
        fontSize: 13,
        color: '#666',
        marginBottom: 8,
    },
    ticketFare: {
        fontSize: 22,
        color: '#1a1a1a',
        fontWeight: '700',
        letterSpacing: 0.5,
    },
    availableCount: {
        fontSize: 13,
        color: '#666',
        marginBottom: 8,
        backgroundColor: '#fff',
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderRadius: 20,
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: '#e6e6e6',
    },
    taxInclusiveExclusive: {
        fontSize: 12,
        color: '#666',
        marginTop: 4,
    },
    showTaxInclusiveText: {
        fontSize: 12,
        color: '#0066cc',
        marginTop: 8,
        fontWeight: '500',
    },
    amenitiesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        gap: 8,
    },
    originDestinationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 5,
    },
    locationText: {
        fontSize: 14,
        color: '#666',
        textAlign: 'center',
    },
    timeText: {
        fontSize: 15,
        color: '#1a1a1a',
        fontWeight: '600',
        marginTop: 4,
    },
    durationContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
    },
    durationText: {
        fontSize: 13,
        color: '#666',
        marginHorizontal: 8,
    },
    dottedLine: {
        color: '#ccc',
    },
    detailsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 10,
        borderTopWidth: 1,
        borderTopColor: '#f0f0f0',
        marginTop: 10,
    },
    detailButton: {
        marginHorizontal: 8,
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderRadius: 16,
        backgroundColor: '#f8f9fa',
    },
    text: {
        fontSize: 12,
        color: '#666',
    },
    showSeatsContainer: {
        width: '100%',
        alignItems: 'center',
        marginVertical: 12,
    },
    showSeatAvailability: {
        backgroundColor: '#0066cc',
        color: 'white',
        padding: 12,
        borderRadius: 8,
        textAlign: 'center',
        overflow: 'hidden',
        fontSize: 14,
        fontWeight: '600',
    },
    showAllFeatures: {
        fontSize: 12,
        color: '#0066cc',
        marginLeft: 4,
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    busIcon: {
        marginTop: 10,
    },
    popularBand: {
        position: 'absolute',
        top: 15,
        left: -30,
        backgroundColor: '#ff3b30',
        paddingVertical: 4,
        paddingHorizontal: 30,
        transform: [{ rotate: '-45deg' }],
        zIndex: 1,
    },
    popularText: {
        color: '#fff',
        fontSize: 11,
        fontWeight: '600',
        letterSpacing: 0.5,
    },
    seatArrangementContainer: {
        backgroundColor: 'white',
        padding: 16,
        marginTop: 8,
        borderRadius: 12,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    finalPriceContainer: {
        marginTop: 8,
        padding: 8,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        alignItems: 'center',
    },
    finalPriceLabel: {
        fontSize: 12,
        color: '#666',
    },
    finalPriceAmount: {
        fontSize: 18,
        color: '#34c759',
        fontWeight: '600',
    },
    originContainer: {
        flexDirection: 'column',
        marginHorizontal: 5,
        alignItems: 'center',
    },
    destinationContainer: {
        flexDirection: 'column',
        marginHorizontal: 5,
        alignItems: 'center',
    }
});

export default BusAvailabilityResults;