import React, { useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, ScrollView, TouchableOpacity, Alert } from 'react-native';
import { signOut } from 'aws-amplify/auth';
import { useUser } from './UserContext';
import ScreenLayout from './ScreenLayout';
import { MaterialIcons } from '@expo/vector-icons';

const THEME = {
    colors: {
        primary: '#2563EB',
        secondary: '#64748B',
        background: '#FFFFFF',
        surface: '#F8FAFC',
        error: '#EF4444',
        text: {
            primary: '#1E293B',
            secondary: '#475569',
            light: '#94A3B8'
        },
        border: '#E2E8F0'
    }
};

function Account({ navigation }) {
    const { user, setUser, userAttributes, setUserAttributes } = useUser();
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (dateString) => {
        if (!dateString) return 'Not provided';
        
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        
        const month = months[date.getMonth()];
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        
        return `${month} ${day}, ${year}`;
    };

    const handleSignOut = async () => {
        setIsLoading(true);
        try {
            await signOut();
            setUser(null);
            setUserAttributes(null);
            Alert.alert('Success', 'Signed out successfully');
            navigation.navigate('Home');
        } catch (error) {
            console.error("Error signing out:", error);
            Alert.alert('Error', 'Failed to sign out. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const renderDetail = (label, value, icon) => (
        <View style={styles.detailRow}>
            <View style={styles.detailIconContainer}>
                <MaterialIcons name={icon} size={24} color={THEME.colors.primary} />
            </View>
            <View style={styles.detailContent}>
                <Text style={styles.detailLabel}>{label}</Text>
                <Text style={styles.detailValue}>{value || 'Not provided'}</Text>
            </View>
        </View>
    );

    return (
        <ScreenLayout navigation={navigation}>
            <ScrollView 
                style={styles.container}
                contentContainerStyle={styles.contentContainer}
            >
                <View style={styles.headerSection}>
                    <View style={styles.avatarContainer}>
                        <Text style={styles.avatarText}>
                            {userAttributes?.given_name?.[0]?.toUpperCase() || '?'}
                        </Text>
                    </View>
                    <Text style={styles.welcomeText}>
                        Welcome, {userAttributes?.given_name || 'User'}
                    </Text>
                </View>

                <View style={styles.card}>
                    <Text style={styles.cardTitle}>Personal Information</Text>
                    {renderDetail('First Name', userAttributes?.given_name, 'person')}
                    {renderDetail('Last Name', userAttributes?.family_name, 'person-outline')}
                    {renderDetail('Email', userAttributes?.email, 'email')}
                    {renderDetail('Date of Birth', formatDate(userAttributes?.birthdate), 'cake')}
                    {renderDetail('Phone', userAttributes?.phone_number, 'phone')}
                </View>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity 
                        style={styles.signOutButton}
                        onPress={handleSignOut}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <ActivityIndicator color="#FFFFFF" size="small" />
                        ) : (
                            <>
                                <MaterialIcons name="logout" size={20} color="#FFFFFF" />
                                <Text style={styles.signOutText}>Sign Out</Text>
                            </>
                        )}
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: THEME.colors.surface,
    },
    contentContainer: {
        padding: 20,
    },
    headerSection: {
        alignItems: 'center',
        marginBottom: 24,
    },
    avatarContainer: {
        width: 80,
        height: 80,
        borderRadius: 40,
        backgroundColor: THEME.colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 12,
    },
    avatarText: {
        fontSize: 32,
        color: THEME.colors.background,
        fontWeight: 'bold',
    },
    welcomeText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: THEME.colors.text.primary,
    },
    card: {
        backgroundColor: THEME.colors.background,
        borderRadius: 16,
        padding: 20,
        marginBottom: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: '600',
        color: THEME.colors.text.primary,
        marginBottom: 16,
    },
    detailRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 12,
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border,
    },
    detailIconContainer: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: THEME.colors.surface,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
    },
    detailContent: {
        flex: 1,
    },
    detailLabel: {
        fontSize: 14,
        color: THEME.colors.text.secondary,
        marginBottom: 4,
    },
    detailValue: {
        fontSize: 16,
        color: THEME.colors.text.primary,
        fontWeight: '500',
    },
    buttonContainer: {
        alignItems: 'center',
    },
    signOutButton: {
        backgroundColor: THEME.colors.error,
        borderRadius: 8,
        paddingVertical: 12,
        paddingHorizontal: 24,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 120,
    },
    signOutText: {
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: '600',
        marginLeft: 8,
    },
});

export default Account;