import React, { useState, useRef } from 'react';
import { 
    View, 
    TextInput, 
    StyleSheet, 
    Text, 
    Alert, 
    TouchableOpacity,
    ActivityIndicator,
    Platform
} from 'react-native';
import { 
    signIn, 
    getCurrentUser, 
    fetchUserAttributes, 
    signUp, 
    confirmSignUp, 
    resetPassword, 
    confirmResetPassword 
} from 'aws-amplify/auth';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import PhoneInput from 'react-native-phone-number-input';
import ScreenLayout from '../components/ScreenLayout';
import { useUser } from '../components/UserContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import { isMobile, isBrowser } from 'react-device-detect';
import axios from 'axios';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const THEME = {
    colors: {
        primary: '#2563EB',
        error: '#EF4444',
        success: '#10B981',
        border: '#E5E7EB',
        text: {
            primary: '#1F2937',
            secondary: '#6B7280',
            white: '#FFFFFF'
        },
        background: {
            main: '#FFFFFF',
            light: '#F3F4F6'
        }
    }
};

// Add custom CSS for the web datepicker
if (isBrowser) {
    const style = document.createElement('style');
    style.textContent = `
        .custom-datepicker {
            width: 100%;
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
        .react-datepicker__input-container {
            width: 100%;
        }
        .react-datepicker__input-container input {
            width: 100%;
            padding: 15px;
            border-radius: 8px;
            border: 1px solid #E5E7EB;
            font-size: 16px;
            background-color: #F3F4F6;
        }
        .date-picker-modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }
        .date-picker-content {
            background: white;
            padding: 20px;
            border-radius: 12px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .react-datepicker {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }
        .react-datepicker__month-dropdown,
        .react-datepicker__year-dropdown {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            max-height: 200px;
            overflow-y: auto;
            padding: 5px;
        }
        .react-datepicker__year-dropdown {
            width: 100px !important;
        }
        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
            margin: 0 5px;
        }
        .react-datepicker__month-select,
        .react-datepicker__year-select {
            padding: 5px;
            border-radius: 4px;
            border: 1px solid #ccc;
        }
        .react-datepicker__month-option:hover,
        .react-datepicker__year-option:hover {
            background-color: #f0f0f0;
        }
    `;
    document.head.appendChild(style);
}

const CustomButton = ({ title, onPress, isLoading, style }) => (
    <TouchableOpacity 
        style={[styles.customButton, style, isLoading && styles.buttonDisabled]}
        onPress={onPress}
        disabled={isLoading}
    >
        {isLoading ? (
            <ActivityIndicator color={THEME.colors.text.white} size="small" />
        ) : (
            <Text style={styles.buttonText}>{title}</Text>
        )}
    </TouchableOpacity>
);

const LoginScreen = ({ navigation }) => {
    const { setUser, setUserAttributes, setUserPoints } = useUser();
    const [password, setPassword] = useState('');
    const [signUpPage, setSignUpPage] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('IN');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmSignUpPage, setConfirmSignUpPage] = useState(false);
    const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSignUpLoading, setIsSignUpLoading] = useState(false);
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
    const [isResetLoading, setIsResetLoading] = useState(false);

    const loadPointsByUser = async (userId) => {
        try {
            const response = await axios.get(
                `${API_URL}/user-points/${userId}`,
                {
                    headers: {
                        'x-api-token': API_TOKEN
                    }
                }
            );
            setUserPoints(response.data.points || 0);
        } catch (error) {
            console.error('Error loading user points:', error);
            setUserPoints(0);
        }
    };

    const handleSuccessfulLogin = async () => {
        const attributes = await fetchUserAttributes();
        const user = await getCurrentUser();
        Alert.alert('Success', 'Logged in successfully');
        setUser(user);
        setUserAttributes(attributes);
        loadPointsByUser(user.userId);
        navigation.navigate('Home');
    };

    const handleAlreadyLoggedInUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            const attributes = await fetchUserAttributes();
            Alert.alert('Already logged in', 'Redirecting to Home');
            setUser(currentUser);
            setUserAttributes(attributes);
            loadPointsByUser(currentUser.userId);
            navigation.navigate('Home');
        } catch (error) {
            console.error("Error handling already logged in user:", error);
            setErrors({ signIn: "Error handling logged in user. Please try again." });
        }
    };

    const handleSignIn = async () => {
        if (!email || !password) {
            setErrors({ signIn: 'Please fill in all fields' });
            return;
        }

        setIsLoading(true);
        try {
            const loginStatus = await signIn({ username: email, password });
            if(loginStatus.isSignedIn) {
                await handleSuccessfulLogin();
            } else if (loginStatus.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                setConfirmSignUpPage(true);
            }
        } catch (error) {
            if (error.message === 'There is already a signed in user.') {
                await handleAlreadyLoggedInUser();
            } else {
                setErrors({ signIn: error.message });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignUp = async () => {
        const errors = {};
        if (!email) errors.email = 'Email is required';
        if (!firstName) errors.firstName = 'First Name is required';
        if (!lastName) errors.lastName = 'Last Name is required';
        if (!password) errors.password = 'Password is required';
        if (!dateOfBirth) errors.dateOfBirth = 'Date of Birth is required';
        if (!phoneNumber) errors.phoneNumber = 'Phone Number is required';

        setErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setIsSignUpLoading(true);
        try {
            await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        email,
                        given_name: firstName,
                        family_name: lastName,
                        birthdate: dateOfBirth,
                        phone_number: phoneNumber
                    }
                }
            });
            Alert.alert('Success', 'Please check your email for confirmation code');
            setSignUpPage(false);
            setConfirmSignUpPage(true);
        } catch (error) {
            setErrors({ signUp: error.message });
        } finally {
            setIsSignUpLoading(false);
        }
    };

    const handleConfirmSignUp = async () => {
        setIsConfirmationLoading(true);
        try {
            await confirmSignUp({ username: email, confirmationCode });
            Alert.alert('Success', 'Account confirmed successfully');
            setSignUpPage(false);
            setConfirmSignUpPage(false);
        } catch (error) {
            setErrors({ confirmSignUp: error.message });
        } finally {
            setIsConfirmationLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setErrors({ forgotPassword: 'Please enter your email' });
            return;
        }

        setIsResetLoading(true);
        try {
            await resetPassword({ username: email });
            Alert.alert('Success', 'Check your email for the reset code');
            setForgotPasswordPage(true);
        } catch (error) {
            setErrors({ forgotPassword: error.message });
        } finally {
            setIsResetLoading(false);
        }
    };

    const handleForgotPasswordSubmit = async () => {
        setIsResetLoading(true);
        try {
            await confirmResetPassword({ 
                username: email, 
                confirmationCode, 
                newPassword 
            });
            Alert.alert('Success', 'Password reset successfully');
            setForgotPasswordPage(false);
        } catch (error) {
            setErrors({ forgotPasswordSubmit: error.message });
        } finally {
            setIsResetLoading(false);
        }
    };

    const handleDateConfirm = (selectedDate) => {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        setDateOfBirth(formattedDate);
        setShowCalendar(false);
    };

    const renderDatePicker = () => {
        if (isBrowser) {
            return (
                <View style={styles.inputContainer}>
                    <Text style={styles.inputLabel}>
                        Date of Birth <Text style={styles.mandatory}>*</Text>
                    </Text>
                    <View style={styles.datePickerContainer}>
                        <TextInput
                            style={[styles.input, { flex: 1 }]}
                            value={dateOfBirth}
                            placeholder="YYYY-MM-DD"
                            onChangeText={(text) => {
                                if (text.match(/^\d{4}-\d{2}-\d{2}$/)) {
                                    const date = new Date(text);
                                    if (!isNaN(date.getTime())) {
                                        setDateOfBirth(text);
                                    }
                                }
                                setDateOfBirth(text);
                            }}
                            onFocus={() => setShowCalendar(true)}
                        />
                        <TouchableOpacity 
                            style={styles.calendarButton} 
                            onPress={() => setShowCalendar(true)}
                        >
                            <MaterialIcons name="calendar-today" size={24} color={THEME.colors.primary} />
                        </TouchableOpacity>
                    </View>
                    {showCalendar && (
                        <div className="date-picker-modal" onClick={() => setShowCalendar(false)}>
                            <div className="date-picker-content" onClick={e => e.stopPropagation()}>
                                <DatePicker
                                    selected={dateOfBirth ? new Date(dateOfBirth) : null}
                                    onChange={(date) => {
                                        const formattedDate = format(date, 'yyyy-MM-dd');
                                        setDateOfBirth(formattedDate);
                                        setShowCalendar(false);
                                    }}
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    inline
                                />
                            </div>
                        </div>
                    )}
                    {errors.dateOfBirth && <Text style={styles.error}>{errors.dateOfBirth}</Text>}
                </View>
            );
        }

        return (
            <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>
                    Date of Birth <Text style={styles.mandatory}>*</Text>
                </Text>
                <View style={styles.datePickerContainer}>
                    <TextInput
                        style={[styles.input, { flex: 1 }]}
                        value={dateOfBirth}
                        placeholder="YYYY-MM-DD"
                        onChangeText={(text) => {
                            if (text.match(/^\d{4}-\d{2}-\d{2}$/)) {
                                const date = new Date(text);
                                if (!isNaN(date.getTime())) {
                                    setDateOfBirth(text);
                                }
                            }
                            setDateOfBirth(text);
                        }}
                    />
                    <TouchableOpacity 
                        style={styles.calendarButton} 
                        onPress={() => setShowCalendar(true)}
                    >
                        <MaterialIcons name="calendar-today" size={24} color={THEME.colors.primary} />
                    </TouchableOpacity>
                </View>
                {errors.dateOfBirth && <Text style={styles.error}>{errors.dateOfBirth}</Text>}
            </View>
        );
    };

    const renderPhoneInput = () => (
        <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>
                Phone Number <Text style={styles.mandatory}>*</Text>
            </Text>
            <View style={styles.phoneInputWrapper}>
                <PhoneInput
                    defaultValue={phoneNumber}
                    defaultCode="IN"
                    layout="first"
                    onChangeFormattedText={text => {
                        setPhoneNumber(text);
                    }}
                    containerStyle={styles.phoneInputContainer}
                    textContainerStyle={styles.phoneTextContainer}
                    textInputStyle={styles.phoneTextInput}
                    codeTextStyle={styles.phoneCodeText}
                    countryPickerButtonStyle={styles.countryPickerButton}
                    flagButtonStyle={styles.flagButton}
                    disableArrowIcon={true}
                />
            </View>
            {errors.phoneNumber && <Text style={styles.error}>{errors.phoneNumber}</Text>}
        </View>
    );

    return (
        <ScreenLayout navigation={navigation}>
            <View style={styles.outerContainer}>
                <View style={styles.formContainer}>
                    <Text style={styles.title}>
                        {forgotPasswordPage ? 'Reset Password' : signUpPage ? 'Sign Up' : 'Sign In'}
                    </Text>

                    <View style={styles.inputContainer}>
                        <Text style={styles.inputLabel}>
                            Email <Text style={styles.mandatory}>*</Text>
                        </Text>
                        <TextInput 
                            style={styles.input} 
                            value={email} 
                            onChangeText={setEmail}
                            keyboardType="email-address"
                            autoCapitalize="none"
                            placeholder="Enter your email"
                        />
                        {errors.email && <Text style={styles.error}>{errors.email}</Text>}
                        {errors.signIn && <Text style={styles.error}>{errors.signIn}</Text>}
                    </View>

                    {forgotPasswordPage ? (
                        <>
                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>Confirmation Code</Text>
                                <TextInput 
                                    style={styles.input} 
                                    value={confirmationCode} 
                                    onChangeText={setConfirmationCode}
                                    placeholder="Enter confirmation code"
                                    keyboardType="number-pad"
                                />
                            </View>
                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>New Password</Text>
                                <TextInput
                                    style={styles.input}
                                    value={newPassword}
                                    onChangeText={setNewPassword}
                                    secureTextEntry
                                    placeholder="Enter new password"
                                />
                            </View>
                            <CustomButton 
                                title="Reset Password" 
                                onPress={handleForgotPasswordSubmit}
                                isLoading={isResetLoading}
                            />
                            <TouchableOpacity onPress={() => setForgotPasswordPage(false)}>
                                <Text style={styles.linkText}>Back to Login</Text>
                            </TouchableOpacity>
                        </>
                    ) : signUpPage ? (
                        <>
                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>
                                    First Name <Text style={styles.mandatory}>*</Text>
                                </Text>
                                <TextInput 
                                    style={styles.input} 
                                    value={firstName} 
                                    onChangeText={setFirstName}
                                    placeholder="Enter your first name"
                                />
                                {errors.firstName && <Text style={styles.error}>{errors.firstName}</Text>}
                            </View>

                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>
                                    Last Name <Text style={styles.mandatory}>*</Text>
                                </Text>
                                <TextInput 
                                    style={styles.input} 
                                    value={lastName} 
                                    onChangeText={setLastName}
                                    placeholder="Enter your last name"
                                />
                                {errors.lastName && <Text style={styles.error}>{errors.lastName}</Text>}
                            </View>

                            {renderDatePicker()}
                            {renderPhoneInput()}

                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>
                                    Password <Text style={styles.mandatory}>*</Text>
                                </Text>
                                <TextInput
                                    style={styles.input}
                                    value={password}
                                    onChangeText={setPassword}
                                    secureTextEntry
                                    placeholder="Create a password"
                                />
                                {errors.password && <Text style={styles.error}>{errors.password}</Text>}
                            </View>

                            <CustomButton 
                                title="Sign Up" 
                                onPress={handleSignUp}
                                isLoading={isSignUpLoading}
                            />
                            <TouchableOpacity onPress={() => setSignUpPage(false)}>
                                <Text style={styles.linkText}>Already have an account? Sign In</Text>
                            </TouchableOpacity>
                        </>) : confirmSignUpPage ? (
                        <>
                            <View style={styles.infoMessage}>
                                <Text style={styles.infoMessageText}>
                                    Please enter the confirmation code sent to your email
                                </Text>
                            </View>
                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>Confirmation Code</Text>
                                <TextInput
                                    style={styles.input}
                                    value={confirmationCode}
                                    onChangeText={setConfirmationCode}
                                    placeholder="Enter confirmation code"
                                    keyboardType="number-pad"
                                />
                            </View>
                            <CustomButton 
                                title="Verify Account" 
                                onPress={handleConfirmSignUp}
                                isLoading={isConfirmationLoading}
                            />
                        </>
                    ) : (
                        <>
                            <View style={styles.inputContainer}>
                                <Text style={styles.inputLabel}>
                                    Password <Text style={styles.mandatory}>*</Text>
                                </Text>
                                <TextInput
                                    style={styles.input}
                                    value={password}
                                    onChangeText={setPassword}
                                    secureTextEntry
                                    placeholder="Enter your password"
                                />
                            </View>
                            <CustomButton 
                                title="Sign In" 
                                onPress={handleSignIn}
                                isLoading={isLoading}
                            />
                            <TouchableOpacity onPress={() => setSignUpPage(true)}>
                                <Text style={styles.linkText}>Don't have an account? Sign Up</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handleForgotPassword}>
                                <Text style={styles.linkText}>Forgot Password?</Text>
                            </TouchableOpacity>
                        </>
                    )}
                </View>
            </View>
            
            {!isBrowser && (
                <DateTimePickerModal
                    isVisible={showCalendar}
                    mode="date"
                    onConfirm={handleDateConfirm}
                    onCancel={() => setShowCalendar(false)}
                    maximumDate={new Date()}
                />
            )}
        </ScreenLayout>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        backgroundColor: THEME.colors.background.light,
    },
    formContainer: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: THEME.colors.background.main,
        padding: 24,
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 3,
        position: 'relative',
        zIndex: 1,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        color: THEME.colors.text.primary,
        marginBottom: 24,
        textAlign: 'center',
    },
    inputContainer: {
        marginBottom: 16,
        width: '100%',
        position: 'relative',
    },
    inputLabel: {
        fontSize: 14,
        fontWeight: '500',
        color: THEME.colors.text.secondary,
        marginBottom: 8,
    },
    input: {
        borderWidth: 1,
        borderColor: THEME.colors.border,
        padding: 15,
        borderRadius: 8,
        fontSize: 16,
        backgroundColor: THEME.colors.background.light,
        width: '100%',
    },
    customButton: {
        backgroundColor: THEME.colors.primary,
        padding: 15,
        borderRadius: 8,
        alignItems: 'center',
        marginVertical: 10,
        elevation: 2,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        width: '100%',
    },
    buttonDisabled: {
        opacity: 0.7,
    },
    buttonText: {
        color: THEME.colors.text.white,
        fontSize: 16,
        fontWeight: '600',
    },
    error: {
        color: THEME.colors.error,
        fontSize: 14,
        marginTop: 5,
    },
    linkText: {
        color: THEME.colors.primary,
        textAlign: 'center',
        marginTop: 15,
        fontSize: 14,
        fontWeight: '500',
    },
    mandatory: {
        color: THEME.colors.error,
    },
    datePickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
    },
    datePickerWrapper: {
        position: 'relative',
        width: '100%',
    },
    calendarButton: {
        padding: 15,
        marginLeft: 8,
        backgroundColor: THEME.colors.background.light,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        height: 52,
        justifyContent: 'center',
        alignItems: 'center',
    },
    phoneInputWrapper: {
        overflow: 'hidden',
        borderRadius: 8,
        marginTop: 4,
        width: '100%',
        borderWidth: 1,
        borderColor: THEME.colors.border,
        height: 52,
    },
    phoneInputContainer: {
        width: '100%',
        backgroundColor: THEME.colors.background.light,
        borderRadius: 8,
        height: 52,
        paddingHorizontal: 0,
        borderWidth: 0,
    },
    phoneTextContainer: {
        backgroundColor: THEME.colors.background.light,
        borderLeftWidth: 1,
        borderLeftColor: THEME.colors.border,
        height: 50,
        paddingVertical: 0,
        paddingHorizontal: 8,
        flex: 1,
        marginLeft: 0,
    },
    phoneTextInput: {
        height: 50,
        backgroundColor: THEME.colors.background.light,
        padding: 0,
        fontSize: 16,
        color: THEME.colors.text.primary,
        flex: 1,
    },
    phoneCodeText: {
        height: 50,
        lineHeight: 50,
        padding: 0,
        marginRight: 0,
        color: THEME.colors.text.primary,
        fontSize: 14,
    },
    countryPickerButton: {
        width: 65,
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: THEME.colors.background.light,
    },
    flagButton: {
        width: 65,
        height: 50,
        padding: 0,
        borderRightWidth: 1,
        borderRightColor: THEME.colors.border,
        marginRight: 0,
        backgroundColor: THEME.colors.background.light,
    },
    infoMessage: {
        backgroundColor: THEME.colors.background.light,
        padding: 16,
        borderRadius: 8,
        marginBottom: 16,
        borderLeftWidth: 4,
        borderLeftColor: THEME.colors.primary,
    },
    infoMessageText: {
        color: THEME.colors.text.secondary,
        fontSize: 14,
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    },
    modalContent: {
        backgroundColor: THEME.colors.background.main,
        padding: 20,
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    }
});

export default LoginScreen;