import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Animated } from 'react-native';
import { Ionicons, AntDesign, MaterialCommunityIcons, Fontisto, MaterialIcons } from '@expo/vector-icons';

const THEME_COLORS = {
    primary: '#2563EB',     // Royal Blue
    secondary: '#6366F1',   // Indigo
    accent: '#3B82F6',      // Sky Blue
    text: {
        primary: '#1F2937',   // Dark Gray
        secondary: '#6B7280', // Medium Gray
        light: '#9CA3AF',     // Light Gray
    },
    background: {
        light: '#F3F4F6',     // Light Background
        white: '#FFFFFF',     // White
    },
    hover: '#DBEAFE',        // Light Blue Hover
    active: '#2563EB',       // Active Blue
};

function MenuBar({ navigation }) {
    const [activeMenu, setActiveMenu] = useState('home'); // Set default to 'home'
    const [scaleValues] = useState({
        home: new Animated.Value(1),
        bus: new Animated.Value(1),
        flight: new Animated.Value(1),
        hotel: new Animated.Value(1),
    });

    useEffect(() => {
        // Set initial active menu based on current route
        const currentRouteName = navigation?.getState()?.routes?.[navigation.getState().index]?.name;
        updateActiveMenu(currentRouteName);
    }, []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('state', () => {
            const currentRouteName = navigation?.getState()?.routes?.[navigation.getState().index]?.name;
            updateActiveMenu(currentRouteName);
        });

        return unsubscribe;
    }, [navigation]);

    const updateActiveMenu = (routeName) => {
        switch(routeName) {
            case 'Home':
                setActiveMenu('home');
                break;
            case 'Bus':
                setActiveMenu('bus');
                break;
            case 'Flights':
                setActiveMenu('flight');
                break;
            case 'Hotels':
                setActiveMenu('hotel');
                break;
            default:
                setActiveMenu('home');
        }
    };

    const animatePress = (menu) => {
        Animated.sequence([
            Animated.timing(scaleValues[menu], {
                toValue: 0.95,
                duration: 100,
                useNativeDriver: true,
            }),
            Animated.timing(scaleValues[menu], {
                toValue: 1,
                duration: 100,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const onMenuPress = (menu) => {
        animatePress(menu);
        if (menu === 'home') {
            navigation.navigate('Home');
        } else if (menu === 'bus') {
            navigation.navigate('Bus');
        } else if (menu === 'flight') {
            navigation.navigate('Flights');
        } else if (menu === 'hotel') {
            navigation.navigate('Hotels');
        }
    };

    const renderMenuItem = (menu, icon, label) => {
        const isActive = activeMenu === menu;
        return (
            <TouchableOpacity 
                onPress={() => onMenuPress(menu)} 
                style={styles.menuItemContainer}
                activeOpacity={0.7}
            >
                <Animated.View 
                    style={[
                        styles.menuItem,
                        isActive && styles.activeMenuItem,
                        { transform: [{ scale: scaleValues[menu] }] }
                    ]}
                >
                    {icon}
                    <Text style={[
                        styles.menuText,
                        isActive && styles.activeMenuText
                    ]}>
                        {label}
                    </Text>
                </Animated.View>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.menuBar}>
            {renderMenuItem(
                'home',
                <Ionicons 
                    name="home-outline" 
                    size={16} 
                    color={activeMenu === 'home' ? THEME_COLORS.active : THEME_COLORS.text.secondary} 
                />,
                'HOME'
            )}
            {renderMenuItem(
                'bus',
                <Ionicons 
                    name="bus-outline" 
                    size={16} 
                    color={activeMenu === 'bus' ? THEME_COLORS.active : THEME_COLORS.text.secondary} 
                />,
                'BUS'
            )}
            {renderMenuItem(
                'flight',
                <MaterialIcons 
                    name="flight" 
                    size={16} 
                    color={activeMenu === 'flight' ? THEME_COLORS.active : THEME_COLORS.text.secondary} 
                />,
                'FLIGHT'
            )}
            {renderMenuItem(
                'hotel',
                <Fontisto 
                    name="hotel" 
                    size={24} 
                    color={activeMenu === 'hotel' ? THEME_COLORS.active : THEME_COLORS.text.secondary} 
                />,
                'HOTEL'
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    menuBar: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: THEME_COLORS.background.white,
        paddingVertical: 12,
        paddingHorizontal: 8,
        borderRadius: 12,
        shadowColor: THEME_COLORS.text.primary,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    menuItemContainer: {
        flex: 1,
        marginHorizontal: 4,
    },
    menuItem: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        paddingHorizontal: 12,
        borderRadius: 8,
        backgroundColor: THEME_COLORS.background.white,
        position: 'relative',
    },
    activeMenuItem: {
        backgroundColor: THEME_COLORS.hover,
    },
    menuText: {
        fontSize: 12,
        fontWeight: '600',
        color: THEME_COLORS.text.secondary,
        marginTop: 4,
        letterSpacing: 0.5,
    },
    activeMenuText: {
        color: THEME_COLORS.active,
        fontWeight: '700',
    },
    activeDot: {
        position: 'absolute',
        bottom: -8,
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: THEME_COLORS.active,
    },
});

export default MenuBar;