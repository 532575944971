import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { UserProvider, useUser } from './components/UserContext';
import HomeScreen from './components/HomeScreen';
import BusSearch from './components/BusSearch';
import FeedbackPage from './components/FeedbackPage';
import LoginScreen from './components/LoginScreen';
import FlightSearch from './components/FlightSearch';
import HotelSearch from './components/HotelSearch';
import ManageReservation from './components/ManageReservation';
import ViewReservation from './components/ViewReservation';
import TrackBus from './components/TrackBus';
import Account from './components/Account';
import SupportScreen from './components/SupportScreen';
import { Amplify } from 'aws-amplify';
import awsmobile from './src/aws-exports';
import { View, StyleSheet } from 'react-native';

Amplify.configure(awsmobile);

const linking = {
    prefixes: ['https://bygbus.com', 'bygbus://'],
    config: {
      screens: {
        Home: '',
        Feedback: 'feedback/:serviceNumber',
        Bus: {
          path: 'bus',
          parse: {
            selectedCity: (selectedCity) => `${selectedCity}`,
          },
        },
        Flights: 'flights',
        Hotels: 'hotels',
        Login: 'login',
        Account: 'account',
        Support: 'support',
        ManageReservation: 'manage-reservation/:pnr',
        ViewReservation: 'reservation/:pnr',
        TrackBus: 'trackbus/:pnr'
        // Add other screens here as needed
      },
    },
  };
  

const Stack = createStackNavigator();
export default function App() {
    return (
        <View style={styles.container}>
        <UserProvider>
            <NavigationContainer linking={linking}>
                <Stack.Navigator initialRouteName="Home">
                    <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false  }} />
                    <Stack.Screen name="Bus" options={{ headerShown: false  }}> 
                        {props => <BusSearch {...props} forceReload={true} />}
                    </Stack.Screen>
                    <Stack.Screen name="Flights" component={FlightSearch} options={{ headerShown: false  }} />
                    <Stack.Screen name="Hotels" component={HotelSearch} options={{ headerShown: false  }} />
                    <Stack.Screen name="Feedback" component={FeedbackPage} options={{ headerShown: false }} />
                    <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
                    <Stack.Screen name="Account" component={Account} options={{ headerShown: false }} />
                    <Stack.Screen name="ManageReservation" component={ManageReservation} options={{ headerShown: false }} />
                    <Stack.Screen name="TrackBus" component={TrackBus} options={{ headerShown: false }} />
                    <Stack.Screen name="ViewReservation" component={ViewReservation}  options={{ headerShown: false }}  />
                    <Stack.Screen name="Support" component={SupportScreen} options={{ headerShown: false }} />
                </Stack.Navigator>
            </NavigationContainer>
        </UserProvider>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
});
