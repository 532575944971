import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, Alert, StyleSheet, ScrollView, CheckBox, TouchableOpacity } from 'react-native';
import axios from 'axios';
import ScreenLayout from './ScreenLayout';
import { useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Print from 'expo-print';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;
const CANCELLATION_ALLOWED = 'CANCELLATION_ALLOWED';

const ManageReservation = ({ navigation }) => {
    const route = useRoute();
    const { pnr: initialPnr } = route.params || {};
    const [pnr, setPnr] = useState(initialPnr || '');
    const [reservationDetails, setReservationDetails] = useState(null);
    const [cancellationDetails, setCancellationDetails] = useState(null);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (initialPnr) {
            fetchReservationDetails();
        }
    }, [initialPnr]);

    const calculateTotals = (seats) => {
        return seats.reduce((totals, seat) => ({
            baseFare: totals.baseFare + (seat.seatRate.baseFare || 0),
            discountedBaseFare: totals.discountedBaseFare + (seat.seatRate.discountedBaseFare || 0),
            tax: totals.tax + (seat.seatRate.totalTax || 0),
            discount: totals.discount + (seat.seatRate.discount || 0),
            total: totals.total + (seat.seatRate.totalCost || 0)
        }), {
            baseFare: 0,
            discountedBaseFare: 0,
            tax: 0,
            discount: 0,
            total: 0
        });
    };

    // Add a helper function for grand total calculation
    const calculateGrandTotal = (details) => {
        const totals = calculateTotals(details.seats);
        return (
            totals.discountedBaseFare +
            totals.tax -
            (details.pointsApplied ? details.pointsApplied / 100 : 0)
        ).toFixed(2);
    };

    const calculateFinalAmount = (details) => {
        const totalAmount = parseFloat(details.totalAmount);
        const totalDiscount = parseFloat(details.totalDiscount || 0);
        const pointsValue = details.pointsApplied ? details.pointsApplied / 100 : 0;
        return (totalAmount - totalDiscount - pointsValue).toFixed(2);
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleSendEmail = async () => {
        if (!email.trim()) {
            Alert.alert('Email is required', 'Please enter an email address.');
            return;
        }

        const requestBody = {
            pnr: reservationDetails.confirmationCode,
            emails: email,
            notificationType: 'CONFIRMATION'
        }

        try {
            const response = await axios.post(API_URL + '/notify', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });
            setMessage(response.data);
            Alert.alert('Success', 'Email sent successfully!');
        } catch (error) {
            console.log(error)
            setError('Failed to send Email. Please try again or contact BYGBUS Customer Support.')
        }
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${day}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;
    };

    const handlePrint = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/print/${reservationDetails.confirmationCode}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
        }
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${pnr}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setReservationDetails(response.data);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    const cancelReservation = async () => {
        try {
            const response = await axios.post(`${API_URL}/cancel/${pnr}`, {}, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setCancellationDetails(response.data);
            setSelectedPassengers([]);
            setShowCancelConfirmation(true);
        } catch (error) {
            console.error('Error cancelling reservation:', error);
            Alert.alert('Error', 'Failed to cancel reservation. Please try again.');
        }
    };

    const confirmCancelSeats = async () => {
        try {
            const requestBody = {
                pnr: pnr,
                ticketCode: cancellationDetails.ticketCode,
                cancelPassengers: selectedPassengers
            };

            const response = await axios.post(`${API_URL}/cancel-confirm`, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });
            setShowConfirmation(true);
        } catch (error) {
            console.error('Error confirming cancellation:', error);
            Alert.alert('Error', 'Failed to confirm cancellation. Please try again.');
        }
    };

    const togglePassengerSelection = (passenger) => {
        setSelectedPassengers((prevSelected) =>
            prevSelected.find((p) => p.seat.seatNo === passenger.seat.seatNo)
                ? prevSelected.filter((p) => p.seat.seatNo !== passenger.seat.seatNo)
                : [...prevSelected, passenger]
        );
    };

    const calculateCancellationCharges = () => {
        return selectedPassengers.reduce((total, passenger) =>
            total + passenger.seat.seatRate.cancellationCharges, 0);
    };

    const calculateTotalRefund = () => {
        return selectedPassengers.reduce((total, passenger) =>
            total + passenger.seat.seatRate.refundAmount, 0);
    };

    return (
        <ScreenLayout navigation={navigation}>
            <ScrollView contentContainerStyle={styles.scrollContainer}>
                <View style={styles.container}>
                    {!showCancelConfirmation && (
                        <View>
                            <Text style={styles.title}>Manage Reservation</Text>
                            <TextInput
                                style={styles.input}
                                placeholder="Enter PNR"
                                value={pnr}
                                onChangeText={setPnr}
                            />
                            <View style={styles.buttonContainer}>
                                <TouchableOpacity
                                    onPress={fetchReservationDetails}
                                    style={styles.retrieveButton}
                                >
                                    <Text style={styles.buttonText}>Retrieve Reservation</Text>
                                </TouchableOpacity>
                            </View>

                            {reservationDetails && (
                                <View style={styles.detailsContainer}>
                                    {/* Basic Details */}
                                    <Text style={styles.detailsTitle}>Reservation Details</Text>
                                    <View style={styles.section}>
                                        <Text style={styles.detailText}>BYGBUS PNR: {reservationDetails.transactionPNR}</Text>
                                        <Text style={styles.detailText}>Operator PNR: {reservationDetails.operatorPNR}</Text>
                                        <Text style={styles.detailText}>Service: {reservationDetails.busAvailability.serviceIdentifier}</Text>
                                        <Text style={[
                                            styles.statusText,
                                            { color: reservationDetails.status === "BOOKED" ? 'green' : 'red' }
                                        ]}>
                                            {reservationDetails.status === "BOOKED" ? "Confirmed" : reservationDetails.status}
                                        </Text>
                                    </View>

                                    {/* Travel Details */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Travel Details</Text>
                                        <Text style={styles.detailText}>
                                            Operator: {reservationDetails.busAvailability.operator}
                                            ({reservationDetails.busAvailability.bus.name})
                                        </Text>
                                        <Text style={styles.detailText}>From: {reservationDetails.origin}</Text>
                                        <Text style={styles.detailText}>To: {reservationDetails.destination}</Text>
                                        <Text style={styles.detailText}>Date: {reservationDetails.travelDate}</Text>
                                        <Text style={styles.detailText}>
                                            Departure: {formatTime(reservationDetails.busAvailability.departureTime)}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}
                                        </Text>
                                    </View>

                                    {/* Fare Details */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Fare Details</Text>
                                        {reservationDetails.seats.map((seat, index) => (
                                            <View key={index} style={styles.fareDetailsCard}>
                                                <Text style={styles.detailText}>Seat {seat.seatNo}</Text>
                                                <Text style={styles.detailText}>Base Fare: ₹{seat.seatRate.baseFare}</Text>
                                                {seat.seatRate.discount > 0 && (
                                                    <>
                                                        <Text style={styles.detailText}>
                                                            Discounted Base: ₹{seat.seatRate.discountedBaseFare}
                                                        </Text>
                                                        <Text style={styles.detailText}>
                                                            Discount: ₹{seat.seatRate.discount}
                                                        </Text>
                                                    </>
                                                )}
                                                <Text style={styles.detailText}>Tax: ₹{seat.seatRate.totalTax}</Text>
                                                <Text style={[styles.detailText, styles.totalCost]}>
                                                    Total: ₹{seat.seatRate.totalCost}
                                                </Text>
                                            </View>
                                        ))}

                                        {/* Fare Summary */}
                                        <View style={styles.fareSummary}>
                                            <Text style={styles.subSectionHeader}>Summary</Text>
                                            <View style={styles.summaryRow}>
                                                <Text style={styles.summaryLabel}>Total Base Fare:</Text>
                                                <Text style={styles.summaryValue}>
                                                    ₹{calculateTotals(reservationDetails.seats).baseFare.toFixed(2)}
                                                </Text>
                                            </View>

                                            {calculateTotals(reservationDetails.seats).discount > 0 && (
                                                <>
                                                    <View style={styles.summaryRow}>
                                                        <Text style={styles.summaryLabel}>Discount:</Text>
                                                        <Text style={styles.summaryValue}>
                                                            -₹{calculateTotals(reservationDetails.seats).discount.toFixed(2)}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.summaryRow}>
                                                        <Text style={styles.summaryLabel}>Discounted Base Fare:</Text>
                                                        <Text style={styles.summaryValue}>
                                                            ₹{calculateTotals(reservationDetails.seats).discountedBaseFare.toFixed(2)}
                                                        </Text>
                                                    </View>
                                                </>
                                            )}

                                            <View style={styles.summaryRow}>
                                                <Text style={styles.summaryLabel}>Total Tax:</Text>
                                                <Text style={styles.summaryValue}>
                                                    ₹{calculateTotals(reservationDetails.seats).tax.toFixed(2)}
                                                </Text>
                                            </View>

                                            {reservationDetails.pointsApplied > 0 && (
                                                <View style={styles.summaryRow}>
                                                    <Text style={styles.summaryLabel}>Points Applied:</Text>
                                                    <Text style={styles.summaryValue}>
                                                        -₹{(reservationDetails.pointsApplied / 100).toFixed(2)}
                                                    </Text>
                                                </View>
                                            )}

                                            {/* Actual Grand Total Calculation */}
                                            <View style={[styles.summaryRow, styles.grandTotal]}>
                                                <Text style={styles.grandTotalLabel}>Grand Total:</Text>
                                                <Text style={styles.grandTotalValue}>
                                                    ₹{(
                                                        calculateTotals(reservationDetails.seats).discountedBaseFare +
                                                        calculateTotals(reservationDetails.seats).tax -
                                                        (reservationDetails.pointsApplied ? reservationDetails.pointsApplied / 100 : 0)
                                                    ).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Contact Details */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Contact Details</Text>
                                        <Text style={styles.detailText}>Email: {reservationDetails.contactEmail}</Text>
                                        <Text style={styles.detailText}>Phone: {reservationDetails.contactNumber}</Text>
                                    </View>

                                    {/* Passenger Details */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Passenger Details</Text>
                                        {reservationDetails.passengersInfo.map((passenger, index) => (
                                            <View key={index} style={styles.passengerCard}>
                                                <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                                <Text style={styles.detailText}>Name: {passenger.firstName} {passenger.lastName}</Text>
                                                <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                                <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                                <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                                <Text style={[
                                                    styles.statusText,
                                                    { color: passenger.seat.seatStatus === 'BOOKED' ? 'green' : 'red' }
                                                ]}>
                                                    Status: {passenger.seat.seatStatus}
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    {/* Boarding Details */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Boarding Point</Text>
                                        <Text style={styles.detailText}>
                                            Location: {reservationDetails.boardingPoint.pointName}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Landmark: {reservationDetails.boardingPoint.landMark}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Contact: {reservationDetails.boardingPoint.contactNumber}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Time: {formatTime(reservationDetails.boardingPoint.arrivalTime)}
                                        </Text>
                                    </View>

                                    {/* Dropping Point */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Dropping Point</Text>
                                        <Text style={styles.detailText}>
                                            Location: {reservationDetails.droppingPoint.pointName}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Landmark: {reservationDetails.droppingPoint.landMark}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Contact: {reservationDetails.droppingPoint.contactNumber}
                                        </Text>
                                        <Text style={styles.detailText}>
                                            Time: {formatTime(reservationDetails.droppingPoint.arrivalTime)}
                                        </Text>
                                    </View>

                                    {/* Cancellation Policy */}
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                                        {reservationDetails.busAvailability.cancellationPolicy?.cancellationPenalty.map((penalty, index) => (
                                            <View key={index} style={styles.policyCard}>
                                                <Text style={styles.detailText}>
                                                    Between {penalty.from ? convertTo12HourFormat(penalty.from) : 'Departure'}
                                                    and {penalty.to ? convertTo12HourFormat(penalty.to) : 'Booking Time'}:
                                                </Text>
                                                <Text style={styles.detailText}>
                                                    {penalty.refundable
                                                        ? `${penalty.chargeType === 'AMOUNT' ? '₹' : ''}${penalty.cancellationCharge}${penalty.chargeType === 'PERCENTAGE' ? '%' : ''} charge`
                                                        : 'Non-Refundable'
                                                    }
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    <View style={styles.section}>
                                        <Text style={styles.text}>
                                        Enter comma separated emails, if you would like to send confirmation to multiple emails
                                    </Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder="Enter Email Address"
                                        keyboardType="email-address"
                                        value={email}
                                        onChangeText={setEmail}
                                    />
                                    </View>

                                    {/* Action Buttons */}
                                    <View style={styles.buttonContainer}>
                                        <TouchableOpacity onPress={cancelReservation} style={styles.cancelButton}>
                                            <Text style={styles.buttonText}>Cancel Reservation</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={handlePrint} style={styles.printButton}>
                                            <Text style={styles.buttonText}>Print Ticket</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={handleSendEmail} style={styles.emailButton}>
                                            <Text style={styles.buttonText}>Email</Text>
                                        </TouchableOpacity>
                                    </View>

                                    {message && <Text style={styles.message}>{message}</Text>}
                                    {error && <Text style={styles.error}>{error}</Text>}
                                </View>
                            )}
                        </View>
                    )}

                    {/* Cancellation Confirmation Section */}
                    {showCancelConfirmation && !showConfirmation && (
                        <View style={styles.container}>
                            <Text style={styles.title}>Cancel Ticket</Text>
                            <View style={styles.detailsContainer}>
                                {/* Basic Cancellation Details */}
                                <View style={styles.section}>
                                    <Text style={styles.sectionHeader}>Travel Information</Text>
                                    <Text style={styles.detailText}>Operator: {cancellationDetails.operator}</Text>
                                    <Text style={styles.detailText}>From: {cancellationDetails.origin}</Text>
                                    <Text style={styles.detailText}>To: {cancellationDetails.destination}</Text>
                                    <Text style={styles.detailText}>Date: {cancellationDetails.travelDate}</Text>
                                </View>

                                {/* Contact Information */}
                                <View style={styles.section}>
                                    <Text style={styles.sectionHeader}>Contact Details</Text>
                                    <Text style={styles.detailText}>Email: {cancellationDetails.contactEmail}</Text>
                                    <Text style={styles.detailText}>Phone: {cancellationDetails.contactNumber}</Text>
                                </View>

                                {/* Passenger Selection */}
                                <View style={styles.section}>
                                    <Text style={styles.sectionHeader}>Select Passengers to Cancel</Text>
                                    {cancellationDetails.passengersInfo.map((passenger, index) => (
                                        <View key={index} style={styles.passengerCard}>
                                            {passenger.seat.seatStatus === CANCELLATION_ALLOWED ? (
                                                <View style={styles.checkboxContainer}>
                                                    <CheckBox
                                                        value={selectedPassengers.some(p => p.seat.seatNo === passenger.seat.seatNo)}
                                                        onValueChange={() => togglePassengerSelection(passenger)}
                                                        style={styles.checkbox}
                                                    />
                                                    <Text style={styles.cancelEligible}>Eligible for Cancellation</Text>
                                                </View>
                                            ) : (
                                                <Text style={styles.nonRefundable}>Non-Refundable</Text>
                                            )}
                                            <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                            <Text style={styles.detailText}>Name: {passenger.firstName} {passenger.lastName}</Text>
                                            <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                            <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                            <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                        </View>
                                    ))}
                                </View>

                                {/* Cancellation Charges */}
                                {selectedPassengers.length > 0 && (
                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Cancellation Details</Text>
                                        {selectedPassengers.map((passenger, index) => (
                                            <View key={index} style={styles.cancellationCard}>
                                                <Text style={styles.seatHeader}>Seat {passenger.seat.seatNo}</Text>
                                                <View style={styles.chargeRow}>
                                                    <Text style={styles.chargeLabel}>Ticket Fare:</Text>
                                                    <Text style={styles.chargeValue}>₹{passenger.seat.seatRate.totalCost}</Text>
                                                </View>
                                                <View style={styles.chargeRow}>
                                                    <Text style={styles.chargeLabel}>Cancellation Charge:</Text>
                                                    <Text style={styles.cancellationAmount}>
                                                        -₹{passenger.seat.seatRate.cancellationCharges}
                                                    </Text>
                                                </View>
                                                <View style={[styles.chargeRow, styles.refundRow]}>
                                                    <Text style={styles.refundLabel}>Refund Amount:</Text>
                                                    <Text style={styles.refundValue}>
                                                        ₹{passenger.seat.seatRate.refundAmount}
                                                    </Text>
                                                </View>
                                            </View>
                                        ))}
                                        <View style={styles.totalCharges}>
                                            <Text style={styles.totalChargesLabel}>Total Refund:</Text>
                                            <Text style={styles.totalRefundValue}>₹{calculateTotalRefund()}</Text>
                                        </View>
                                    </View>
                                )}

                                {/* Confirm Cancel Button */}
                                {selectedPassengers.length > 0 && (
                                    <View style={styles.buttonContainer}>
                                        <TouchableOpacity
                                            onPress={confirmCancelSeats}
                                            style={styles.confirmCancelButton}
                                        >
                                            <Text style={styles.buttonText}>Confirm Cancellation</Text>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}

                    {/* Final Confirmation Screen */}
                    {showCancelConfirmation && showConfirmation && (
                        <View style={styles.container}>
                            <View style={styles.confirmationContainer}>
                                <Text style={styles.title}>Cancellation Confirmed</Text>
                                <Text style={styles.confirmationMessage}>
                                    Your cancellation has been processed successfully.
                                </Text>
                                <Text style={styles.refundMessage}>
                                    Your refund of ₹{calculateTotalRefund()} will be processed within 5-7 business days.
                                </Text>
                            </View>
                        </View>
                    )}
                </View>
            </ScrollView>
        </ScreenLayout>
    );
};

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
        alignItems: 'center',
    },
    container: {
        width: '90%',
        padding: 16,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
        color: '#333',
    },
    input: {
        height: 40,
        width: '100%',
        borderColor: '#ddd',
        borderWidth: 1,
        marginBottom: 16,
        paddingHorizontal: 12,
        borderRadius: 8,
        backgroundColor: '#fff',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginVertical: 16,
    },
    retrieveButton: {
        backgroundColor: '#2ecc71',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 150,
        alignItems: 'center',
    },
    cancelButton: {
        backgroundColor: '#e74c3c',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 150,
        alignItems: 'center',
    },
    printButton: {
        backgroundColor: '#3498db',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 150,
        alignItems: 'center',
    },
    emailButton: {
        backgroundColor: '#2ecc71',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        minWidth: 150,
        alignItems: 'center',
    },
    confirmCancelButton: {
        backgroundColor: '#e74c3c',
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        width: '100%',
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    detailsContainer: {
        backgroundColor: '#fff',
        padding: 16,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 12,
        color: '#2c3e50',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingBottom: 8,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 8,
        color: '#444',
    },
    statusText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 8,
    },
    fareDetailsCard: {
        backgroundColor: '#fff',
        padding: 12,
        marginBottom: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    totalCost: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#2c3e50',
        borderTopWidth: 1,
        borderTopColor: '#eee',
        paddingTop: 8,
        marginTop: 8,
    },
    passengerCard: {
        backgroundColor: '#fff',
        padding: 16,
        marginBottom: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        color: '#2c3e50',
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    checkbox: {
        marginRight: 8,
    },
    cancelEligible: {
        color: '#27ae60',
        fontWeight: 'bold',
    },
    nonRefundable: {
        color: '#e74c3c',
        fontWeight: 'bold',
        marginBottom: 8,
    },
    cancellationCard: {
        backgroundColor: '#fff',
        padding: 16,
        marginBottom: 12,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    seatHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 12,
        color: '#2c3e50',
    },
    chargeRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    chargeLabel: {
        fontSize: 15,
        color: '#666',
    },
    chargeValue: {
        fontSize: 15,
        fontWeight: '500',
        color: '#2c3e50',
    },
    cancellationAmount: {
        fontSize: 15,
        fontWeight: '500',
        color: '#e74c3c',
    },
    refundRow: {
        borderTopWidth: 1,
        borderTopColor: '#eee',
        paddingTop: 8,
        marginTop: 8,
    },
    refundLabel: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#27ae60',
    },
    refundValue: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#27ae60',
    },
    totalCharges: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
        paddingTop: 16,
        borderTopWidth: 2,
        borderTopColor: '#eee',
    },
    totalChargesLabel: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2c3e50',
    },
    totalRefundValue: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#27ae60',
    },
    confirmationContainer: {
        alignItems: 'center',
        padding: 24,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    confirmationMessage: {
        fontSize: 18,
        color: '#2c3e50',
        textAlign: 'center',
        marginBottom: 16,
    },
    refundMessage: {
        fontSize: 16,
        color: '#27ae60',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    fareSummary: {
        marginTop: 16,
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    summaryLabel: {
        fontSize: 15,
        color: '#666',
    },
    summaryValue: {
        fontSize: 15,
        fontWeight: '500',
        color: '#2c3e50',
    },
    grandTotal: {
        marginTop: 12,
        paddingTop: 12,
        borderTopWidth: 1,
        borderTopColor: '#eee',
    },
    grandTotalLabel: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#2c3e50',
    },
    grandTotalValue: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#2c3e50',
    },
    message: {
        fontSize: 16,
        color: '#28a745',
        textAlign: 'center',
        marginBottom: 10,
    },
    error: {
        fontSize: 16,
        color: '#dc3545',
        textAlign: 'center',
        marginBottom: 10,
    },
});

export default ManageReservation;