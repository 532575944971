import React, { useState, useEffect, useMemo } from 'react';
import { View, ActivityIndicator, StyleSheet, ImageBackground, Text } from 'react-native';
import axios from 'axios';
import BusSearchContainer from './BusSearchContainer';
import BusAvailabilityResults from './BusAvailabilityResults';
import BusAvailabilitiesFilterSort from './BusAvailabilitiesFilterSort';
import BusPaymentSummary from './BusPaymentSummary';
import BusBookingForm from './BusBookingForm';
import { FontAwesome5 } from '@expo/vector-icons';
import BusBookingReview from './BusBookingReview';
import BusBookingConfirmation from './BusBookingConfirmation';
import BusTripSummary from './BusTripSummary';
import ScreenLayout from './ScreenLayout';
import { format } from 'date-fns';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

function BusSearch({ navigation, route, forceReload }) {
    const [availabilities, setAvailabilities] = useState([]);
    const [displayAvailabilities, setDisplayAvailabilities] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showBookingForm, setShowBookingForm] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [filters, setFilters] = useState({
        ac: false,
        nonAc: false,
        sleeper: false,
        semiSleeper: false,
        pushback: false,
        minPrice: 0,
        maxPrice: 10000,
        operators: [],
        boardingPoints: [],
        dropOffPoints: [],
    });
    const [sortBy, setSortBy] = useState({
        price: null,
        departure: null,
        arrival: null
    });
    const [bookSummary, setBookSummary] = useState({
        origin: '',
        destination: route.params?.selectedCity || '',
        travelDate: new Date(),
        promoCode: '',
        useRewardPoints: false,
        pointsApplied: 0,
        busAvailability: '',
        seats: [],
        boardingPoint: '',
        dropOffPoint: '',
        passengers: [],
        contactEmail: '',
        contactPhone: '',
        confirmationCode: '',
        ticketCode: '',
        orderId: '',
        orderStatus: '',
        totalCost: 0,
        paymentId: '',
        operatorPNR: '',
        validatedPromo: null
    });

    useEffect(() => {
        if (forceReload) {
            resetState();
        }
    }, [forceReload]);

    useEffect(() => {
        if (route.params?.promoCode) {
            setBookSummary(prevState => ({
                ...prevState,
                promoCode: route.params.promoCode
            }));
        }

        if (route.params?.origin) {
            setBookSummary(prevState => ({
                ...prevState,
                promoCode: route.params.origin
            }));
        }

        if (route.params?.destination) {
            setBookSummary(prevState => ({
                ...prevState,
                promoCode: route.params.destination
            }));
        }
    }, [route.params]);

    const resetState = () => {
        setAvailabilities([]);
        setDisplayAvailabilities(false);
        setLoading(false);
        setShowSearch(true);
        setShowBookingForm(false);
        setShowPaymentForm(false);
        setShowReviewForm(false);
        setShowConfirmation(false);
        setFilters({
            ac: false,
        nonAc: false,
        sleeper: false,
        semiSleeper: false,
        pushback: false,
        minPrice: 0,
        maxPrice: 10000,
        operators: [],
        boardingPoints: [],
        dropOffPoints: [],
        });
        setSortBy({price: null,
            departure: null,
            arrival: null});
        setBookSummary({
            origin: '',
            destination: '',
            travelDate: new Date(),
            promoCode: '',
            useRewardPoints: false,
            pointsApplied: 0,
            busAvailability: '',
            seats: [],
            boardingPoint: '',
            dropOffPoint: '',
            passengers: [],
            contactEmail: '',
            contactPhone: '',
            confirmationCode: '',
            ticketCode: '',
            orderId: '',
            orderStatus: '',
            totalCost: 0,
            paymentId: '',
            operatorPNR: '',
            validatedPromo: null
        });
    };

    const filteredAndSortedBuses = useMemo(() => {
        let updatedBuses = [...availabilities];

        // Apply filters
        if (filters.ac) updatedBuses = updatedBuses.filter(bus => bus.features.includes('CLIMATE_AC'));
        if (filters.nonAc) updatedBuses = updatedBuses.filter(bus => bus.features.includes('CLIMATE_NONAC'));
        if (filters.sleeper) updatedBuses = updatedBuses.filter(bus => 
            bus.seatTicketFares.some(fare => 
                ['SLEEPER', 'LOWER_SLEEPER', 'UPPER_SLEEPER', 'SINGLE_LOWER_SLEEPER', 'SINGLE_UPPER_SLEEPER'].includes(fare.seatType)
            )
        );
        if (filters.semiSleeper) updatedBuses = updatedBuses.filter(bus => 
            bus.seatTicketFares.some(fare => fare.seatType === 'SEMI_SLEEPER')
        );
        if (filters.pushback) updatedBuses = updatedBuses.filter(bus => 
            bus.seatTicketFares.some(fare => fare.seatType === 'PUSHBACK')
        );
        updatedBuses = updatedBuses.filter(bus => 
            bus.seatTicketFares.some(fare => 
                fare.ticketFare.baseFare >= filters.minPrice && fare.ticketFare.baseFare <= filters.maxPrice
            )
        );
        if (filters.operators.length > 0) {
            updatedBuses = updatedBuses.filter(bus => filters.operators.includes(bus.operator));
        }
        if (filters.boardingPoints.length > 0) {
            updatedBuses = updatedBuses.filter(bus => 
                bus.boardingPoints.some(point => filters.boardingPoints.includes(point.pointName))
            );
        }
        if (filters.dropOffPoints.length > 0) {
            updatedBuses = updatedBuses.filter(bus => 
                bus.dropOffPoints.some(point => filters.dropOffPoints.includes(point.pointName))
            );
        }

        // Apply sorting
        const getTimeFromArray = (timeArray) => {
            return new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        };

        const getLowestFare = (seatTicketFares) => 
            Math.min(...seatTicketFares.map(fare => fare.ticketFare.discountedBaseFare));

        updatedBuses.sort((a, b) => {
            if (sortBy.price) {
                const priceComparison = sortBy.price === 'Price ↑' 
                    ? getLowestFare(a.seatTicketFares) - getLowestFare(b.seatTicketFares)
                    : getLowestFare(b.seatTicketFares) - getLowestFare(a.seatTicketFares);
                if (priceComparison !== 0) return priceComparison;
            }
            if (sortBy.departure) {
                const departureComparison = sortBy.departure === 'Departure ↑'
                    ? getTimeFromArray(a.departureTime) - getTimeFromArray(b.departureTime)
                    : getTimeFromArray(b.departureTime) - getTimeFromArray(a.departureTime);
                if (departureComparison !== 0) return departureComparison;
            }
            if (sortBy.arrival) {
                const arrivalComparison = sortBy.arrival === 'Arrival ↑'
                    ? getTimeFromArray(a.arrivalTime) - getTimeFromArray(b.arrivalTime)
                    : getTimeFromArray(b.arrivalTime) - getTimeFromArray(a.arrivalTime);
                return arrivalComparison;
            }
            return 0;
        });

        return updatedBuses;
    }, [availabilities, filters, sortBy]);

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };

    const onSearch = async () => {
        setAvailabilities([]);
        setLoading(true);
        setShowErrorMessage(false);
        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const response = await axios.get(`${API_URL}/availabilities`, {
                params: { 
                    origin: bookSummary.origin, 
                    destination: bookSummary.destination, 
                    travelDate: formattedDate 
                },
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setAvailabilities(response.data.availabilities);
            if(!response.data.availabilities.length > 0) {
                setShowErrorMessage(true);
            } else {
                displayAvailabilityResults();
            }
            
        } catch (error) {
            console.error('Error fetching availabilities:', error);
            // Handle error (e.g., show error message to user)
        } finally {
            setLoading(false);
        }
    };

    const displayAvailabilityResults = () => {
        setShowSearch(false);
        setDisplayAvailabilities(true);
        setShowBookingForm(false);
        setShowPaymentForm(false);
        setShowReviewForm(false);
    };

    const handleBookSummaryChange = (updatedBookSummary) => {
        setBookSummary(prevBookSummary => ({
            ...prevBookSummary,
            ...updatedBookSummary,
        }));
    };

    const onModify = () => {
        setShowSearch(true);
        setDisplayAvailabilities(false);
        setShowBookingForm(false);
        setShowPaymentForm(false);
        setShowReviewForm(false);
    };

    const onBookSeats = () => {
        setShowBookingForm(true);
        setDisplayAvailabilities(false);
        setShowSearch(false);
        setShowPaymentForm(false);
        setShowReviewForm(false);
    };

    const onProceedPassenger = () => {
        setShowSearch(false);
        setShowReviewForm(true);
        setShowBookingForm(false);
        setDisplayAvailabilities(false);
        setShowPaymentForm(false);
    };

    const onProceedReview = () => {
        setShowSearch(false);
        setShowPaymentForm(true);
        setShowReviewForm(false);
        setShowBookingForm(false);
        setDisplayAvailabilities(false);
    };

    const onProceedPayment = () => {
        setShowSearch(false);
        setShowPaymentForm(false);
        setShowReviewForm(false);
        setShowBookingForm(false);
        setDisplayAvailabilities(false);
        setShowConfirmation(true);
    };

    const onClickBack = () => {
        if (showBookingForm) {
            displayAvailabilityResults();
            setBookSummary(prevBookSummary => ({
                ...prevBookSummary,
                busAvailability: '',
                seats: [],
                boardingPoint: '',
                dropOffPoint: '',
                passengers: [],
                contactEmail: '',
                contactPhone: '',
                confirmationCode: '',
                ticketCode: '',
                orderId: '',
                orderStatus: '',
                totalCost: 0,
                paymentId: '',
                operatorPNR: ''
            }));
        } else if (showReviewForm) {
            onBookSeats();
        }
    };

    const handleGoHome = () => {
        resetState();
        navigation.navigate('Home');
    };

    const handleViewReservation = () => {
        navigation.navigate('ViewReservation', { pnr: bookSummary.confirmationCode });
    };

    return (
        <ScreenLayout navigation={navigation}>
            <View style={styles.dataContainer}>
                
                    {showSearch && (
                        <View style={styles.searchSummaryContainer}>
                            <BusSearchContainer 
                                onSearch={onSearch}
                                bookSummary={bookSummary}
                                handleBookSummaryChange={handleBookSummaryChange}
                            />

                        { showErrorMessage && (
                            <View style={styles.noAvailabilityContainer}>
                            <Text style={styles.noAvailabilityText}>
                                No Buses Available for this route/day
                            </Text>
                        </View>
                        )}
                        </View>

                    )}
                    {loading && (
                        <View style={styles.loadingContainer}>
                            <ActivityIndicator size="large" color="#5EBC67" />
                            <FontAwesome5 name="bus" size={24} color="#5EBC67" style={styles.busIcon} />
                        </View>
                    )}
                    {displayAvailabilities && (
                        <View style={styles.busAvailabilitiesContainer}>
                            <BusTripSummary bookSummary={bookSummary} onModify={onModify} />
                            <BusAvailabilitiesFilterSort 
                                onFilterChange={handleFilterChange} 
                                onSortChange={handleSortChange}
                                busAvailabilities={availabilities}
                                operators={[...new Set(availabilities.map(bus => bus.operator))]}
                                boardingPoints={[...new Set(availabilities.flatMap(bus => bus.boardingPoints.map(point => point.pointName)))]}
                                dropOffPoints={[...new Set(availabilities.flatMap(bus => bus.dropOffPoints.map(point => point.pointName)))]}
/>
                            <BusAvailabilityResults 
                                busAvailabilities={filteredAndSortedBuses} 
                                bookSummary={bookSummary} 
                                handleBookSummaryChange={handleBookSummaryChange} 
                                onBook={onBookSeats} 
                            />
                        </View>
                    )}
                    {showBookingForm && (
                        <View style={styles.bookingFormContainer}>
                            <BusTripSummary bookSummary={bookSummary} onModify={onModify} />
                            <BusBookingForm 
                                bookSummary={bookSummary}
                                handleBookSummaryChange={handleBookSummaryChange}
                                onProceed={onProceedPassenger}
                                onClickBack={onClickBack}
                            />
                        </View>
                    )}
                    {showReviewForm && (
                        <View style={styles.reviewFormContainer}>
                            <BusBookingReview 
                                initialBookSummary={bookSummary}
                                handlePaymentSuccess={onProceedPayment}
                                onEdit={onClickBack}
                                handleBookSummaryChange={handleBookSummaryChange}
                            />
                        </View>
                    )}
                    {showPaymentForm && (
                        <BusPaymentSummary 
                            bookSummary={bookSummary} 
                            handleBookSummaryChange={handleBookSummaryChange}
                            handlePaymentSuccess={onProceedPayment} 
                        />
                    )}
                    {showConfirmation && (
                        <BusBookingConfirmation 
                            bookSummary={bookSummary} 
                            handleViewReservation={handleViewReservation} 
                            onHome={handleGoHome}
                        />
                    )}

            </View>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    dataContainer: {
        height: '100%'
    },
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    searchSummaryContainer: {
        marginBottom: 5
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    busIcon: {
        marginTop: 10,
    },
    busAvailabilitiesContainer: {
        marginTop: 5,
        marginBottom: 10,
    },
    bookingFormContainer: {
        marginTop: 5,
        marginBottom: 10,
    },
    reviewFormContainer: {
        marginTop: 5,
        marginBottom: 10,
    },
    noAvailabilityContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    noAvailabilityText: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#555',
    },
});

export default BusSearch;