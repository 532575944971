import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Alert, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import axios from 'axios';
import ScreenLayout from './ScreenLayout';
import { useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Print from 'expo-print';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const ViewReservation = ({ navigation }) => {
    const route = useRoute();
    const { pnr: initialPnr } = route.params || {};
    const [pnr, setPnr] = useState(initialPnr || '');
    const [reservationDetails, setReservationDetails] = useState(null);

    useEffect(() => {
        if (initialPnr) {
            fetchReservationDetails();
        }
    }, [initialPnr]);

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        return `${day}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;
    };

    const calculateTotals = (seats) => {
        return seats.reduce((totals, seat) => ({
            baseFare: totals.baseFare + (seat.seatRate.baseFare || 0),
            discountedBaseFare: totals.discountedBaseFare + (seat.seatRate.discountedBaseFare || 0),
            tax: totals.tax + (seat.seatRate.totalTax || 0),
            discount: totals.discount + (seat.seatRate.discount || 0),
            total: totals.total + (seat.seatRate.totalCost || 0)
        }), { baseFare: 0, discountedBaseFare: 0, tax: 0, discount: 0, total: 0 });
    };

    const calculateGrandTotal = (details) => {
        if (!details || !details.seats) return "0.00";

        const totals = calculateTotals(details.seats);
        const pointsValue = details.pointsApplied ? details.pointsApplied / 100 : 0;
        const promoDiscount = details.validatedPromo?.discountDetails?.discountAmount || 0;

        return (
            totals.discountedBaseFare +
            totals.tax -
            pointsValue -
            promoDiscount
        ).toFixed(2);
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${pnr}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setReservationDetails(response.data);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    const handlePrint = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/print/${reservationDetails.confirmationCode}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
        }
    };

    const manageReservation = () => {
        navigation.navigate('ManageReservation', { pnr });
    }

    return (
        <ScreenLayout navigation={navigation}>
            <ScrollView contentContainerStyle={styles.container}>
                <Text style={styles.title}>View Reservation</Text>
                <TextInput
                    style={styles.input}
                    placeholder="Enter PNR"
                    value={pnr}
                    onChangeText={setPnr}
                />
                <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={fetchReservationDetails} style={styles.retrieveButton}>
                        <Text style={styles.buttonText}>Retrieve Reservation</Text>
                    </TouchableOpacity>
                </View>

                {reservationDetails && (
                    <View style={styles.detailsContainer}>
                        <Text style={styles.sectionHeader}>Reservation Details</Text>
                        <Text style={styles.detailText}>BYGBUS PNR: {reservationDetails.transactionPNR}</Text>
                        <Text style={styles.detailText}>Operator PNR: {reservationDetails.operatorPNR}</Text>
                        <Text style={styles.detailText}>Transaction ID: {reservationDetails.transactionId}</Text>
                        
                        <View style={styles.statusContainer}>
                            {reservationDetails.status === "BOOKED" && (
                                <Text style={[styles.statusText, { color: 'green' }]}>CONFIRMED</Text>
                            )}
                            {reservationDetails.status === "CANCELLED" && (
                                <Text style={[styles.statusText, { color: 'red' }]}>CANCELLED</Text>
                            )}
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Journey Details</Text>
                            <Text style={styles.detailText}>
                                From: {reservationDetails.origin} To: {reservationDetails.destination}
                            </Text>
                            <Text style={styles.detailText}>Date: {reservationDetails.travelDate}</Text>
                            <Text style={styles.detailText}>
                                Departure: {formatTime(reservationDetails.busAvailability.departureTime)}
                            </Text>
                            <Text style={styles.detailText}>
                                Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}
                            </Text>
                            <Text style={styles.detailText}>
                                Bus Type: {reservationDetails.busAvailability.bus.name}
                            </Text>
                            <Text style={styles.detailText}>
                                Service No: {reservationDetails.busAvailability.serviceIdentifier}
                            </Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Boarding Point</Text>
                            <Text style={styles.detailText}>
                                Location: {reservationDetails.boardingPoint.pointName}
                            </Text>
                            <Text style={styles.detailText}>
                                Landmark: {reservationDetails.boardingPoint.landMark}
                            </Text>
                            <Text style={styles.detailText}>
                                Time: {formatTime(reservationDetails.boardingPoint.arrivalTime)}
                            </Text>
                            <Text style={styles.detailText}>
                                Contact: {reservationDetails.boardingPoint.contactNumber}
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Dropping Point</Text>
                            <Text style={styles.detailText}>
                                Location: {reservationDetails.droppingPoint.pointName}
                            </Text>
                            <Text style={styles.detailText}>
                                Landmark: {reservationDetails.droppingPoint.landMark}
                            </Text>
                            <Text style={styles.detailText}>
                                Time: {formatTime(reservationDetails.droppingPoint.arrivalTime)}
                            </Text>
                            <Text style={styles.detailText}>
                                Contact: {reservationDetails.droppingPoint.contactNumber}
                            </Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Passenger Details</Text>
                            {reservationDetails.passengersInfo.map((passenger, index) => (
                                <View key={index} style={styles.passengerCard}>
                                    <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                    <Text style={styles.detailText}>Name: {passenger.firstName} {passenger.lastName}</Text>
                                    <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                    <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                    <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                    <Text style={[
                                        styles.detailText,
                                        { color: passenger.seat.seatStatus === 'BOOKED' ? 'green' : 'red' }
                                    ]}>
                                        Status: {passenger.seat.seatStatus}
                                    </Text>
                                </View>
                            ))}
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Fare Details</Text>
                            {reservationDetails.seats.map((seat, index) => (
                                <View key={index} style={styles.fareDetailsCard}>
                                    <Text style={styles.detailText}>Seat {seat.seatNo}</Text>
                                    <Text style={styles.detailText}>Base Fare: ₹{seat.seatRate.baseFare}</Text>
                                    {seat.seatRate.discount > 0 && (
                                        <>
                                            <Text style={styles.detailText}>
                                                Discounted Base: ₹{seat.seatRate.discountedBaseFare}
                                            </Text>
                                            <Text style={styles.detailText}>
                                                Discount: ₹{seat.seatRate.discount}
                                            </Text>
                                        </>
                                    )}
                                    <Text style={styles.detailText}>Tax: ₹{seat.seatRate.totalTax}</Text>
                                    <Text style={[styles.detailText, styles.totalCost]}>
                                        Total: ₹{seat.seatRate.totalCost}
                                    </Text>
                                </View>
                            ))}

                            {/* Fare Summary */}
                            <View style={styles.fareSummary}>
                                <Text style={styles.summaryHeader}>Summary</Text>
                                <View style={styles.summaryRow}>
                                    <Text>Total Base Fare:</Text>
                                    <Text>₹{calculateTotals(reservationDetails.seats).baseFare.toFixed(2)}</Text>
                                </View>
                                {calculateTotals(reservationDetails.seats).discount > 0 && (
                                    <>
                                        <View style={styles.summaryRow}>
                                            <Text>Discount ({reservationDetails.promoCode}):</Text>
                                            <Text>-₹{calculateTotals(reservationDetails.seats).discount.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.summaryRow}>
                                            <Text>Discounted Base Fare:</Text>
                                            <Text>₹{calculateTotals(reservationDetails.seats).discountedBaseFare.toFixed(2)}</Text>
                                        </View>
                                    </>
                                )}
                                <View style={styles.summaryRow}>
                                    <Text>Total Tax:</Text>
                                    <Text>₹{calculateTotals(reservationDetails.seats).tax.toFixed(2)}</Text>
                                </View>
                                {reservationDetails.pointsApplied > 0 && (
                                    <View style={styles.summaryRow}>
                                        <Text>Reward Points Applied:</Text>
                                        <Text>-₹{(reservationDetails.pointsApplied / 100).toFixed(2)}</Text>
                                    </View>
                                )}
                                <View style={[styles.summaryRow, styles.grandTotal]}>
                                    <Text style={styles.grandTotalText}>Grand Total:</Text>
                                    <Text style={styles.grandTotalText}>₹{calculateGrandTotal(reservationDetails)}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity onPress={manageReservation} style={styles.manageButton}>
                                <Text style={styles.buttonText}>Manage Booking</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handlePrint} style={styles.printButton}>
                                <Text style={styles.buttonText}>Print Ticket</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </ScrollView>
        </ScreenLayout>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        height: 40,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 12,
        marginBottom: 16,
    },
    section: {
        marginBottom: 20,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 12,
        color: '#333',
    },
    detailText: {
        fontSize: 16,
        marginBottom: 8,
        color: '#444',
    },
    statusContainer: {
        marginVertical: 12,
    },
    statusText: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    passengerCard: {
        marginBottom: 16,
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    fareDetailsCard: {
        marginBottom: 16,
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    totalCost: {
        fontWeight: 'bold',
        marginTop: 8,
    },
    fareSummary: {
        marginTop: 20,
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#eee',
    },
    summaryHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 12,
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    grandTotal: {
        marginTop: 12,
        paddingTop: 12,
        borderTopWidth: 1,
        borderTopColor: '#eee',
    },
    grandTotalText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20,
    },
    manageButton: {
        backgroundColor: '#e74c3c',
        padding: 12,
        borderRadius: 8,
        minWidth: 140,
        alignItems: 'center',
    },
    printButton: {
        backgroundColor: '#3498db',
        padding: 12,
        borderRadius: 8,
        minWidth: 140,
        alignItems: 'center',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    retrieveButton: {
        backgroundColor: '#2ecc71',
        padding: 12,
        borderRadius: 8,
        width: '100%',
        alignItems: 'center',
    },
});

export default ViewReservation;